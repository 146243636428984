import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap'
import withLayout from '../layout';
import Link from '../components/Link';




const ReforestPage = () => (
    <>
        <br></br>

        <Container>
            <h2 className="text-center"><strong>
                <FormattedMessage id="reforest.placeholder" />
            </strong>
            </h2>
            <p>
                <FormattedMessage id="reforest.content1" />
            </p>
            <p>
                <FormattedMessage id="reforest.content2" />
            </p>
            <p>
                <FormattedMessage id="reforest.content3" />
            </p>
            <p>
                <FormattedMessage id="reforest.content4" />
            </p>
            <p>
                <FormattedMessage id="reforest.content5" />
            </p>
            <p>
                <FormattedMessage id="reforest.content6" />
            </p>
            <p>
                <FormattedMessage id="reforest.content7" />
            </p>
            <p>
                <FormattedMessage id="reforest.content8" />
            </p>
            <p>
                <FormattedMessage id="reforest.content9" />
            </p>
            <p>
                <FormattedMessage id="reforest.content10" />
            </p>
            <p>
                <FormattedMessage id="reforest.content11" />
            </p>
            <p>
                <FormattedMessage id="reforest.content12" />
            </p>
            <p>
                <FormattedMessage id="reforest.content13" />
            </p>
            <p>
                <FormattedMessage id="reforest.content14" />
            </p>
            <p>
                <FormattedMessage id="reforest.content15" />
            </p>
            <p>
                <FormattedMessage id="reforest.content16" />
            </p>
            <p>
                <strong>
                    <Link to='/trees' className='nav-link'><FormattedMessage id="reforest.content17" /></Link>
                </strong>
            </p>
            <p>
                <FormattedMessage id="reforest.content20" />
            </p>
            <p>
                <FormattedMessage id="reforest.content21" />
            </p>
            <p>
                <FormattedMessage id="reforest.content22" />
            </p>
            <p>
                <FormattedMessage id="reforest.content23" />
            </p>
            <p>
                <FormattedMessage id="reforest.content24" />
            </p>
            <p>
                <FormattedMessage id="reforest.content25" />
            </p>
            <p>
                <FormattedMessage id="reforest.content26" />
            </p>
            <p>
                <FormattedMessage id="reforest.content27" />
            </p>
            <p>
                <FormattedMessage id="reforest.content28" />
            </p>
            <p>
                <FormattedMessage id="reforest.content29" />
            </p>
            <p>
                <FormattedMessage id="reforest.content30" />
            </p>
            <p>
                <FormattedMessage id="reforest.content31" />
            </p>
            <p>
                <FormattedMessage id="reforest.content32" />
            </p>
            <p>
                <FormattedMessage id="reforest.content33" />
            </p>
            <p>
                <FormattedMessage id="reforest.content34" />
            </p>
            <p>
                <FormattedMessage id="reforest.content35" />
            </p>
            <p>
                <FormattedMessage id="reforest.content36" />
            </p>
            <p>
                <FormattedMessage id="reforest.content37" />
            </p>
            <p>
                <FormattedMessage id="reforest.content38" />
            </p>
            <p>
                <FormattedMessage id="reforest.content39" />
            </p>
            <p>
                <FormattedMessage id="reforest.content41" />
            </p>
            <p>
                <FormattedMessage id="reforest.content42" />
            </p>
            <p>
                <FormattedMessage id="reforest.content43" />
            </p>
            <p>
                <FormattedMessage id="reforest.content44" />
            </p>
            <p>
                <FormattedMessage id="reforest.content45" />
            </p>
            <p>
                <FormattedMessage id="reforest.content46" />
            </p>
            <p>
                <FormattedMessage id="reforest.content47" />
            </p>
            <p>
                <FormattedMessage id="reforest.content48" />
            </p>
            <p>
                <FormattedMessage id="reforest.content49" />
            </p>
            <p>
                <FormattedMessage id="reforest.content50" />
            </p>
            <p>
                <FormattedMessage id="reforest.content51" />
            </p>
            <p>
                <FormattedMessage id="reforest.content52" />
            </p>
            <p>
                <FormattedMessage id="reforest.content53" />
            </p>
            <p>
                <FormattedMessage id="reforest.content54" />
            </p>
            <p>
                <FormattedMessage id="reforest.content55" />
            </p>
            <p>
                <FormattedMessage id="reforest.content56" />
            </p>
            <p>
                <FormattedMessage id="reforest.content57" />
            </p>
            <p>
                <FormattedMessage id="reforest.content577" />
            </p>
            <p>
                <FormattedMessage id="reforest.content58" />
            </p>
            <p>
                <FormattedMessage id="reforest.content59" />
            </p>
            <p>
                <FormattedMessage id="reforest.content60" />
            </p>
            <p>
                <FormattedMessage id="reforest.content61" />
            </p>
            <p>
                <FormattedMessage id="reforest.content62" />
            </p>
            <p>
                <FormattedMessage id="reforest.content63" />
            </p>
            <p>
                <FormattedMessage id="reforest.content64" />
            </p>
            <p>
                <FormattedMessage id="reforest.content65" />
            </p>
            <p>
                <FormattedMessage id="reforest.content66" />
            </p>
            <p>
                <FormattedMessage id="reforest.content666" />
            </p>
            <p>
                <FormattedMessage id="reforest.content67" />
            </p>
            <p>
                <FormattedMessage id="reforest.content68" />
            </p>
            <p>
                <FormattedMessage id="reforest.content69" />
            </p>
            <p>
                <FormattedMessage id="reforest.content70" />
            </p>
            <p>
                <FormattedMessage id="reforest.content71" />
            </p>
            <p>
                <FormattedMessage id="reforest.content72" />
            </p>
            <p>
                <FormattedMessage id="reforest.content73" />
            </p>
            <p>
                <FormattedMessage id="reforest.content74" />
            </p>
            <p>
                <FormattedMessage id="reforest.content76" />
            </p>
            <p>
                <FormattedMessage id="reforest.content77" />
            </p>
            <p>
                <FormattedMessage id="reforest.content78" />
            </p>
            <p>
                <FormattedMessage id="reforest.content79" />
            </p>
            <p>
                <FormattedMessage id="reforest.content80" />
            </p>
            <p>
                <FormattedMessage id="reforest.content81" />
            </p>
            <p>
                <FormattedMessage id="reforest.content82" />
            </p>
            <p>
                <FormattedMessage id="reforest.content83" />
            </p>
            <p>
                <FormattedMessage id="reforest.content84" />
            </p>
            <p>
                <FormattedMessage id="reforest.content85" />
            </p>
            <p>
                <FormattedMessage id="reforest.content86" />
            </p>
            <p>
                <FormattedMessage id="reforest.content87" />
            </p>
            <p>
                <FormattedMessage id="reforest.content88" />
            </p>
            <p>
                <FormattedMessage id="reforest.content89" />
            </p>


            <Link to="/">
                <FormattedMessage id="page2.Go back to the homepage" />
            </Link>
            <footer className="my-5">
        </footer>
        </Container>

    </>
);

const customProps = {
    localeKey: 'reforest',
};


export default withLayout(customProps)(ReforestPage);
